<template>
<!-- 付款计划  -->
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      <global-table :rowChildren="rowChildren" :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table>
      <dialog-box  ref="addDialog" dialogWidth="750px"  :dialogShow="dialogShow" @handleClose='handleClose' @confirmAdd= "confirmAdd"  title="新增付款计划" componentName="PayPlanAdddialog"
        :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' },{label:'确认',action:'confirmAdd', type:'primary', size:'mini'}]">
    </dialog-box>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import ListMenu from '@/components/actionList/ListMenu.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import DialogBox from '@/components/plug/DialogBox.vue'
import { request, requestForm } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/payplan'
export default {
  components: { ListSearch, GlobalTable, ListMenu, DialogBox },
  name: 'PayPlan',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      menudata: menudata,
      searchdata: SearchData,
      dialogShow: false,
      rowChildren: {
        data1: [],
        data2: [],
        data3: []
      },
      paginationData: { pageSize: pageSize, total: 1 },
      pageSize: pageSize,
      pageNum: 1,
      // showData: {},
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.PayplanList()
  },
  methods: {
    PayplanList () { // 付款计划列表
      request('/api/reconciliation/list', 'get', { pageNum: 1, pageSize: this.pageSize }).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.total }
        }
      })
    },
    confirmAdd () { // 新增付款计划确认按钮
      var isPass = true
      var data = this.$refs.addDialog.$refs.content.$refs.addpayplan
      data.validate((valid) => {
        if (!valid) {
          isPass = false
        }
      })
      if (!isPass) {
        this.$message({
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
      var form = this.$refs.addDialog.$refs.content.$data.form
      request('/api/reconciliation/save', 'post', form).then((Response) => {
        if (Response.code === '200') {
          this.$message({
            message: '新增成功',
            type: 'success'
          })
          this.dialogShow = false
          this.PayplanList()
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    tableAction: function (index, data, action) {
      if (action.id === '1') {
        data.judgeEdit = '2'
      } else if (action.id === '2') {
        this.$confirm('确认删除？', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          requestForm('/api/reconciliation/delete/' + data.id, 'DELETE').then((Response) => {
            if (Response.code === '200') {
              this.$message({
                message: '删除成功',
                type: 'success'
              })
              request('/api/reconciliation/list', 'get', { pageNum: 1, pageSize: this.pageSize }).then((Response) => {
                if (Response.code === '200') {
                  this.tableData = Response.data.records
                  this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
                }
              })
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
      } else if (action.id === '3') {
        var obj = {
          ...this.tableData[index],
          planMoney: this.rowChildren.data3[index].rowData.planMoney
        }
        obj.planStartTime = this.dateFormate(this.rowChildren.data1[index].rowData.planStartTime)
        obj.planEndTime = this.dateFormate(this.rowChildren.data2[index].rowData.planEndTime)
        request('/api/reconciliation/save', 'post', obj).then((Response) => {
          if (Response.code === '200') {
            this.tableData = Response.data
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.PayplanList()
          }
        })
      } else if (action.id === '4') {
        data.judgeEdit = '1'
        this.$message({
          type: 'info',
          message: '已取消'
        })
      }
    },
    buttonClick () {
      this.dialogShow = true
    },
    handleClose () {
      this.dialogShow = false
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.createDate
      if (data.createDate) {
        this.searchdata.startTime = this.dateFormate(data.createDate[1])
        this.searchdata.endTime = this.dateFormate(data.createDate[0])
      }
      request('/api/reconciliation/list', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/reconciliation/list', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.list{
:deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
    width: 135px;
}
}

</style>
