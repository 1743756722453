export var SearchList = [
  { labe: '计划单号', code: 'planCode', type: 'input' },
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' },
  { labe: '银行名称', code: 'bankName', type: 'input' },
  {
    labe: '创建日期',
    code: 'createDate',
    type: 'daterange'
  }
]
export var SearchData = {
  planCode: '',
  company: '',
  bankName: '',
  // createDate: '',
  startTime: '',
  endTime: ''
}

export var menudata = [{
  label: '+新增付款计划',
  action: 'CreateaStatement',
  id: 1
}]

export var tableField = [
  {
    label: '序号',
    code: '',
    type: 'function',
    width: '60',
    handle: (index) => {
      return index + 1
    }
  },
  { label: '公司', code: 'companyName', type: 'input', width: '120' },
  { label: '银行名称', code: 'bankName', type: 'input', width: '120' },
  { label: '银行账号', code: 'bankAccount', type: 'input', width: '140' },
  {
    label: '计划时间从',
    code: 'planStartTime',
    width: '148px',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          props: ['rowChildren'],
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            this.rowChildren.data1[scope.$index] = this
          },
          methods: {
            clk: function () {
            }
          },
          template: `<span>
          <span v-if="rowData.judgeEdit!='2'">{{rowData.planStartTime}}</span>
          <el-date-picker class="changeinput" v-if="rowData.judgeEdit=='2'" v-model="rowData.planStartTime" type="date"></el-date-picker>
          </span>`
        }
      }
    }
  },
  {
    label: '计划时间至',
    code: 'planEndTime',
    width: '148px',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          props: ['rowChildren'],
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            this.rowChildren.data2[scope.$index] = this
          },
          methods: {
            clk: function () {
            }
          },
          template: `<span>
          <span v-if="rowData.judgeEdit!='2'">{{rowData.planEndTime}}</span>
          <el-date-picker  v-if="rowData.judgeEdit=='2'" v-model="rowData.planEndTime" type="date"></el-date-picker>
          </span>`
        }
      }
    }
  },
  {
    label: '计划资金',
    code: 'planMoney',
    width: '100px',
    type: 'vnode',
    handle: function (scope) {
      if (scope) {
        return {
          props: ['rowChildren'],
          data: function () {
            return {
              rowData: { ...scope.row }
            }
          },
          created () {
            this.rowChildren.data3[scope.$index] = this
          },
          methods: {
            clk: function () {
            }
          },
          template: `<span>
          <span v-if="rowData.judgeEdit!='2'">{{rowData.planMoney}}</span>
          <el-input v-if="rowData.judgeEdit=='2'" v-model="rowData.planMoney" placeholder="请输入数量"></el-input>
          </span>`
        }
      }
    }
  },
  { label: '已申请总额', code: 'appliedAmount', type: 'input', width: '110' },
  { label: '已付款总额', code: 'payAmount', type: 'input', width: '110' },
  { label: '余额', code: 'balance', type: 'input', width: '' },
  // { label: '创建人', code: 'createUserName', type: 'input', width: '' },
  // { label: '创建日期', code: 'createDate', type: 'input', width: '' },
  { label: '备注', code: 'bz', type: 'input', width: '' },
  { label: '计划单号', code: 'planCode', type: 'input', width: '110' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    actionData: [
      {
        actionLabel: '编辑',
        id: '1',
        stateField: 'judgeEdit',
        stateSymbol: '!=',
        stateValue: '2'

      },
      {
        actionLabel: '删除',
        id: '2',
        stateField: 'judgeEdit',
        stateSymbol: '!=',
        stateValue: '2'
      },
      {
        actionLabel: '保存',
        id: '3',
        stateField: 'judgeEdit',
        stateSymbol: '==',
        stateValue: '2'
      },
      {
        actionLabel: '取消',
        id: '4',
        stateField: 'judgeEdit',
        stateSymbol: '==',
        stateValue: '2'
      }
    ]
  }
]

export var tableData = []
